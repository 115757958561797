<template>
  <div class="flex w-full flex-col gap-5">
    <div class="flex flex-col gap-2.5">
      <div class="flex items-center justify-between gap-6 text-sm text-white/50">
        <span>Rate</span>
        <span>1 {{ inAssetName }} = {{ fiatPriceRatio }} {{ outAssetName }}</span>
      </div>
      <div class="flex items-center justify-between gap-6">
        <span class="text-sm text-white/50">Estimated Time</span>
        <div class="flex items-center gap-[5px] text-base font-semibold">
          <Icon icon="mynaui:clock-two" />
          {{ estimatedTime }}
        </div>
      </div>
      <div class="flex items-center justify-between gap-6">
        <span class="text-sm text-white/50">Price Impact</span>
        <span class="flex items-center gap-[5px] text-base font-semibold">
          {{ priceImpactPercentage }}%
        </span>
      </div>
      <div class="flex items-center justify-between gap-6">
        <span class="text-sm text-white/50">Route via</span>
        <div
          class="flex items-center gap-[5px] text-base font-semibold"
          data-testid="swap-confirm-provider"
        >
          {{ getNormalizedProviderName(provider) }}
          <img class="aspect-square h-[18px] rounded-full" :alt="provider" :src="swapperLogo" />
        </div>
      </div>
    </div>
    <div class="border-t border-solid border-t-white/10" />
    <div class="flex w-full flex-col gap-2.5">
      <div class="flex items-center justify-between gap-2.5">
        <span class="text-sm text-white/50">Network Fee</span>
        <span v-if="fees.networkUsd" class="text-white/70">{{ toPriceUsd(fees.networkUsd) }}</span>
        <span v-else class="flex items-center gap-1 text-white/70">
          <Icon icon="material-symbols:warning" /> Missing estimation
        </span>
      </div>
      <div class="flex items-center justify-between gap-2.5">
        <span class="text-sm text-white/50">Protocol Fee</span>
        <span class="text-white/70">
          {{ toPriceUsd(fees.protocolUsd) }}
        </span>
      </div>
      <div class="flex items-center justify-between gap-2.5">
        <span class="text-sm text-white/50">Affiliate Fee</span>
        <span class="text-white/70">
          {{ toPriceUsd(fees.affiliateUsd) }}
        </span>
      </div>
      <template v-if="!isJoinCCTransaction">
        <div
          v-if="isCCMember"
          class="-mx-6 box-content flex w-full flex-col gap-3.5 bg-[#33FF99]/10 px-6 py-5 transition-all duration-200"
        >
          <div class="flex gap-2">
            <span class="text-white/50">Affiliate Fee</span>
            <span class="ml-auto font-semibold text-[#33ff99]/70">0</span>
            <span class="text-white/50 line-through">
              {{ toPriceUsd(fees.affiliateUsd) }}
            </span>
          </div>
          <div class="flex items-center gap-2 text-[#33ff99]">
            <Icon icon="lucide:sparkles" />
            <span>No platform fees for our country club members!</span>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="!isCCMember || isJoinCCTransaction"
      class="border-t border-solid border-t-white/10"
    />
    <div :class="{ 'text-xs': true, 'text-white/50': true }">
      Output is estimated. You will receive at least
      <span data-testid="swap-confirm-min-out">{{ amountOutMin }}</span>
      {{ outAssetName }} or the transaction will revert
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { Icon } from '@iconify/vue/dist/iconify.js'
import { toPriceUsd } from '~/utils/numbers'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { getNormalizedProviderName } from '~/utils/swapkit'
// import { useDoritoJoinModalStore } from '~/store/doritoJoinModalStore'

const props = defineProps<{
  inAssetName: string
  isExpectedToJoinCC: boolean
  outAssetName: string
  provider: string
  swapperLogo: string
  fiatPriceRatio: number
  fees: {
    [K in 'affiliateUsd' | 'protocolUsd' | 'networkUsd' | 'totalUsd']: number
  }
  estimatedTimeInSeconds: number
  amountOutMin: string
  priceImpactPercentage: number
  isJoinCCTransaction?: boolean
}>()

const { isCCMember } = storeToRefs(useSwapkitWalletStore())

const estimatedTime = computed(() => {
  const hours = Math.floor(props.estimatedTimeInSeconds / 3600)
  const minutesAndSeconds = props.estimatedTimeInSeconds % 3600
  const minutes = Math.floor(minutesAndSeconds / 60)
  const seconds = Math.floor(minutesAndSeconds % 60)
  return [...(hours ? [hours] : []), minutes, seconds]
    .map((value) => value.toString().padStart(2, '0'))
    .join(':')
})

// const { showDoritoJoinModal } = useDoritoJoinModalStore()
</script>
