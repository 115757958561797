<template>
  <div class="grid gap-5">
    <div class="h-2.5 w-full overflow-hidden rounded-full bg-white/5">
      <div class="h-full bg-[#33FF99]" :style="`width: ${progress}%`" />
    </div>
    <div class="flex items-center justify-between gap-6">
      <span class="text-sm text-white/50">Estimated Time</span>
      <div class="flex items-center gap-[5px] text-base font-semibold">
        <Icon icon="mynaui:clock-two" />
        {{ estimatedTime }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{ estimatedTimeInSeconds: number }>()

const estimatedTime = computed(() => {
  const hours = Math.floor(props.estimatedTimeInSeconds / 3600)
  const minutesAndSeconds = props.estimatedTimeInSeconds % 3600
  const minutes = Math.floor(minutesAndSeconds / 60)
  const seconds = Math.floor(minutesAndSeconds % 60)
  return [...(hours ? [hours] : []), minutes, seconds]
    .map((value) => value.toString().padStart(2, '0'))
    .join(':')
})

const progress = ref(0)
onMounted(() => {
  progress.value = (0.1 / props.estimatedTimeInSeconds) * 100
})
watch(progress, (_progress) => {
  setTimeout(() => {
    if (_progress < 100) {
      progress.value =
        (((_progress * props.estimatedTimeInSeconds) / 100 + 0.1) / props.estimatedTimeInSeconds) *
        100
    }
  }, 100)
})
</script>
