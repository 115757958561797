<template>
  <div
    class="-mx-6 box-content flex w-full flex-col gap-5 px-6 transition-all duration-200"
    :class="[expanded ? 'my-3.5 bg-white/5 py-6' : '']"
  >
    <div class="flex items-center justify-between gap-2.5">
      <span class="text-sm text-[#ECBA33]">{{ title }}</span>
      <div class="flex-1 border-t border-solid border-t-white/10" />
      <button
        class="cursor-pointer rounded-full bg-white/5 p-1.5 text-xs text-white/70 hover:bg-[#ECBA33]/10 hover:text-white"
        @click="handleExpand"
      >
        <Icon :icon="expanded ? 'mingcute:up-line' : 'mingcute:down-line'" />
      </button>
    </div>
    <template v-if="expanded">
      <slot />
    </template>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { ref } from 'vue'

const props = defineProps<{
  title?: string
  defaultExpanded?: true
}>()

const expanded = ref(!!props.defaultExpanded)
const handleExpand = () => {
  expanded.value = !expanded.value
}
</script>
