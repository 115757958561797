<template>
  <div class="grid h-full w-full gap-6 p-6">
    <a
      role="button"
      aria-label="Close"
      class="mb-20 ml-auto cursor-pointer rounded-lg bg-[#0001] p-1.5 backdrop-blur-sm"
      @click="onClose"
    >
      <Icon icon="iconamoon:sign-times" />
    </a>
    <div class="larken">
      Hold <span class="text-[#ECBA33]">$DORITO</span>
      <br />
      <span>and get access to our</span>
      <div class="mt-2.5 drop-shadow-md">
        <img class="h-8" src="/images/country-club.png" alt="" />
      </div>
    </div>
    <div class="text-xs text-white/70">
      Hold {{ doritoAmount }} $DORITO to become a member of the Crypto's Country Club &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <a class="inline-flex cursor-pointer items-center gap-1 leading-none text-[#00CCFF]">
        learn more
        <Icon icon="majesticons:open" />
      </a>
    </div>
    <AssetInput
      :asset-name="poolIn.assetName"
      :asset-logo-uri="poolIn.logoURI"
      :asset-chain="poolIn.chainName"
      asset-select-label="Select input asset"
      amount-label="Swap input amount"
      :price-display="valueInDisplay"
      price-test-id="swap-input-dollar-value-dorito"
      :balance-display="balanceInDisplay"
      :select-open="props.assetSelectorVisible"
      :selected-asset-id="poolIn.assetId"
      @amount-ref="setAmountRef"
      @amount-change="onAmountChange"
      @open-asset-select="onShowAssetSelector()"
      @select-max="onSelectMax()"
    />
    <div class="grid rounded-[20px] bg-gradient-to-b from-transparent to-[#33FF991A]">
      <button
        :class="[
          'larken flex items-center justify-center gap-2.5 rounded-full bg-[#ECBA33] p-2.5 leading-none text-[#1D2021]',
          {
            'cursor-pointer': !isDisabled,
            'opacity-50': isDisabled,
          },
        ]"
        :disabled="isDisabled"
        @click="handleBuy"
      >
        {{ swapActionName }}
        <Icon class="-rotate-45" icon="fluent:arrow-right-24-regular" />
      </button>
      <div class="grid gap-2.5 p-4">
        <div
          v-if="quoteRaw && quoteRaw.quote?.route?.outputAmountMin"
          class="flex items-center justify-center gap-1 text-xs"
        >
          <span>You will receive at least</span>
          <span class="text-[#ECBA33]">{{ quoteRaw.quote?.route?.outputAmountMin }} $DORITO</span>
        </div>
        <div class="flex items-center justify-center gap-1 text-xs">
          <span>You already own</span>
          <Icon icon="uit:wallet" />
          <span class="text-[#ECBA33]">{{ CCBalance }} $DORITO</span>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <SubAssetSelect
        :key="subAssetSelectKey"
        :open="props.assetSelectorVisible"
        :pool-to-update="'poolIn'"
        :cur-selected-asset="poolIn"
        :curated-routes="conditionalCuratedAssetSelectorData"
        :searchable-routes="conditionalSearchableAssetSelectorData"
        @select="onAssetSelect"
        @close="onHideAssetSelector()"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { type ComponentPublicInstance, type ComputedRef, computed, ref } from 'vue'
import { type QuoteResponse, RoutingResultType, type SwapResponse } from 'rango-sdk-basic'
import { storeToRefs } from 'pinia'
import { useRuntimeConfig } from 'nuxt/app'
import AssetInput from './AssetInput.vue'
import { debounce, generateNonce, getSynthSaveAssetId, hasMessage } from '~/utils/main'
import type { WalletAsset } from '~/wallets/swapkit'
import { floatToInt, toPrice } from '~/utils/numbers'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { DISPLAY_DECIMALS, formatNumber } from '~/utils/utils'
import { type State, stateTypes } from '~/types/state'
import type { FiatPrice } from '~/types/fiatPrice'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import { type SwapStatus, createAmountSchema, isSwapDisabled, swapActionNames } from '~/utils/swap'
import type { AssetSelectorData } from '~/types/route'
import { useRouteStore } from '~/store/routeStore'
import SubAssetSelect from '~/components/Modal/SubAssetSelect.vue'
import { useRangoRouteStore } from '~/store/rangoRouteStore'
import { useSnackbarMessage } from '~/store/snackbar'
import { getHumanError } from '~/utils/humanErrors'
import { type QuoteWithAmountIn } from '~/types/dorito'
import type { PoolForDialog, RouteEndPoint } from '~/types'
import { RangoChain } from '~/types/rango'
import { useConnectWalletModalStore } from '~/store/connectWalletModalStore'

const props = defineProps<{
  assetSelectorVisible: boolean
  swapStatus: SwapStatus
}>()

const rangoRouteStore = useRangoRouteStore()
const {
  fetchQuote: fetchQuoteRango,
  // fetchOutputRoutes: fetchOutputRoutesRango
} = rangoRouteStore
const { routes } = storeToRefs(rangoRouteStore)
const snackbar = useSnackbarMessage()

const doritoId = ref(useRuntimeConfig().public.CC_ASSET_ID)
const doritoAmount = ref(useRuntimeConfig().public.CC_AMOUNT)

const emits = defineEmits<{
  close: []
  hideAssetSelector: []
  onBuy: [quoteWithAmountIn: QuoteWithAmountIn]
  showAssetSelector: []
  statusChange: [SwapStatus]
}>()

const isDoritoWalletConnected = computed(() => {
  return !!swapkitWalletStore.isChainConnected(RangoChain.Solana)
})
const isDisabled = computed(() => {
  return isSwapDisabled(props.swapStatus)
})
const membershipSwapActionNames = { ...swapActionNames, canSwap: 'Buy $DORITO' }
const swapActionName = computed(() => {
  return (
    (isLoadingBalances.value && !hasBalances.value && 'Loading balances...') ||
    (isDoritoWalletConnected.value && membershipSwapActionNames[props.swapStatus]) ||
    'Connect Solana Wallet'
  )
})
const handleBuy = () => {
  if (!isDoritoWalletConnected.value) {
    connectWalletModalStore.showConnectWalletModal()
    return
  }
  emits('onBuy', {
    amountIn: amount.value.toString(),
    quoteRaw: quoteRaw.value,
  })
}

const routeStore = useRouteStore()

const poolIn = ref<RouteEndPoint>({
  asset: 'SOLANA.SOL',
  assetName: 'SOL',
  assetId: 'SOLANA.SOL',
  assetAddress: null,
  chain: RangoChain.Solana,
  chainName: 'Solana',
  integrations: ['rango'],
  decimals: 9,
  logoURI: 'https://crispy.sfo3.cdn.digitaloceanspaces.com/solana.sol.png',
})

const subAssetSelectKey = ref(0)
const onShowAssetSelector = () => {
  subAssetSelectKey.value += 1
  emits('showAssetSelector')
}
const onHideAssetSelector = () => {
  emits('hideAssetSelector')
}
const onAssetSelect = ({
  newSelectedAsset,
}: {
  poolToUpdate: PoolForDialog
  newSelectedAsset: RouteEndPoint
}) => {
  poolIn.value = newSelectedAsset
}
const connectWalletModalStore = useConnectWalletModalStore()
const swapkitWalletStore = useSwapkitWalletStore()
const fiatPriceStore = useFiatPriceStore()
const { fiatPrices } = storeToRefs(fiatPriceStore)

const { wallets, CCBalance, isLoadingBalances, hasBalances } = storeToRefs(swapkitWalletStore)

const amountIn = ref<string>('0')
const amountRef = ref<HTMLInputElement | null>(null)
const setAmountRef = (maybeElement: Element | ComponentPublicInstance | null) => {
  if (!(maybeElement instanceof HTMLInputElement)) {
    return
  }
  amountRef.value = maybeElement
}

const amount = computed<number>(() => {
  return amountIn.value === '' ? 0 : parseFloat(amountIn.value)
})

const assetIn = computed<WalletAsset | undefined>(() => {
  return wallets.value[poolIn.value.chain]?.balance.find(({ chain, symbol, ticker, address }) => {
    return getSynthSaveAssetId({ chain, symbol, ticker, address }) === poolIn.value.assetId
  })
})

const valueIn = computed(() => {
  return Number.isNaN(amount.value) ? 0 : amount.value * fiatPriceIn.value
})

const valueInDisplay = computed(() => {
  return toPrice(valueIn.value, 'USD')
})

const balanceIn = computed(() => {
  const balance = assetIn.value?.getValue('number') ?? 0
  return balance
})

const balanceInDisplay = computed(() => {
  return formatNumber(balanceIn.value, DISPLAY_DECIMALS[poolIn.value.assetId])
})

const fiatPriceIn = computed<number>(() => {
  const fiatPrice: State<FiatPrice> | undefined = fiatPrices.value[poolIn.value.assetId]
  return fiatPrice?.state === stateTypes.Loaded ? fiatPrice.data.usd : 0
})
const onAmountChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  if (e.target.value === '.') {
    amountIn.value = '0.'
    if (amountRef.value) {
      amountRef.value.value = '0.'
    }
    return
  }
  const value = e.target.value.replaceAll(',', '')
  const amountSchema = createAmountSchema(poolIn.value.decimals)
  const amountResult = amountSchema.safeParse(value)
  if (amountResult.error) {
    // * if new value is invalid revert to previous value
    if (!amountRef.value) {
      return
    }
    amountRef.value.value = amountIn.value
    return
  }
  amountIn.value = amountResult.data
  debouncedFetchQuotes()
}

const conditionalCuratedAssetSelectorData: ComputedRef<State<AssetSelectorData[]>> = computed(
  () => {
    return {
      state: 'loaded',
      data: [...routeStore.getCuratedAssetSelectorData]
        .flatMap((chainAssets) => {
          if (chainAssets.chain !== 'SOLANA') {
            return []
          }
          return {
            ...chainAssets,
            assets: chainAssets.assets.filter((asset) => {
              return asset.assetId !== doritoId.value
            }),
          }
        })
        .sort((a, b) => a.chain.localeCompare(b.chain)),
    }
  },
)

const conditionalSearchableAssetSelectorData: ComputedRef<State<AssetSelectorData[]>> = computed(
  () => {
    return {
      state: 'loaded',
      data: routeStore.getAssetSelectorData.filter(({ chain }) => chain === 'SOLANA'),
    }
  },
)

const onSelectMax = () => {
  if (!amountRef.value) {
    return
  }
  const max = balanceIn.value.toString()
  amountIn.value = max
  amountRef.value.value = max

  fetchQuote({ poolIn })
}

const quoteRaw = ref<QuoteRango | null>(null)

const debouncedFetchQuotes = debounce(() => {
  if (!amount.value) {
    quoteRaw.value = null
    return
  }
  fetchQuote({ poolIn })
}, 350)

let nonce = generateNonce()
type FetchQuotesProps = {
  poolIn: { value: RouteEndPoint }
  poolOut: { value: RouteEndPoint }
}

const slippagePercentage = 5.5

const fetchQuote = async ({ poolIn }: Omit<FetchQuotesProps, 'poolOut'>) => {
  // Reset the quote
  quoteRaw.value = null

  // * if amount is 0 or NaN do not fetch quotes
  if (!amount.value) {
    return
  }

  nonce = generateNonce()

  emits('statusChange', 'fetchingQuote')
  // TODO look up these values based on env var, fetch cap sensitive address from routes

  const poolOut = routes.value.find(
    ({ assetId }) => assetId.toUpperCase() === doritoId.value.toUpperCase(),
  )

  if (!poolOut) throw new Error('Dorito Asset / Route not found')

  const fromAddress = wallets.value[poolIn.value.chain]?.address
  const toAddress = wallets.value.SOLANA?.address

  const currentNonce = nonce

  try {
    // route via rango
    const rangoAmount = floatToInt(amount.value, poolIn.value.decimals)
    const blockchainIn = poolIn.value.chain
    const blockchainOut = poolOut.chain
    const quote: IntegrationQuote<'rango'> = await (fromAddress && toAddress
      ? rangoRouteStore
          .swap({
            amount: rangoAmount,
            from: {
              blockchain: blockchainIn,
              symbol: poolIn.value.assetName,
              address: poolIn.value.assetAddress,
            },
            to: {
              blockchain: blockchainOut,
              symbol: poolOut.assetName,
              address: poolOut.assetAddress,
            },
            fromAddress,
            toAddress,
            slippage: slippagePercentage.toString(),
          })
          .then<{
            integration: 'rango'
            quote: SwapResponse
          }>((swapResponse) => {
            return {
              integration: 'rango',
              quote: swapResponse,
            }
          })
      : fetchQuoteRango(poolIn.value, poolOut, rangoAmount).then<{
          integration: 'rango'
          quote: QuoteResponse
        }>((quote) => {
          return {
            integration: 'rango',
            quote,
          }
        }))
    // Only update the displayed quotes if this is the most recent api call,
    // otherwise disregard the returned data
    if (nonce !== currentNonce) {
      return
    }
    quoteRaw.value = quote
    emits(
      'statusChange',
      quote.quote.resultType === RoutingResultType.OK && quote.quote.route
        ? 'canSwap'
        : 'quoteError',
    )
  } catch (error) {
    emits('statusChange', 'quoteError')
    const title = 'Failed to load Rango quote'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    window.newrelic?.noticeError(errorMessage || title)
    snackbar.addError({
      title,
      text: getHumanError(errorMessage),
    })
  }
}
const onClose = () => {
  emits('close')
}
</script>
