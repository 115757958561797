<template>
  <ModalBgWrapper
    :open="isDoritoJoinModalVisible && !isCCMember"
    class="z-[100] bg-transparent"
    body-class="md:mr-4 md:mt-[70px] rounded-none border-none md:bg-[#0000]"
    @close="onClose"
  >
    <div
      class="grid gap-[30px] rounded-3xl p-1 backdrop-blur-sm max-md:rounded-b-none max-md:p-0 max-md:pt-1 md:w-[375px]"
      style="
        background: linear-gradient(transparent, #62632e),
          linear-gradient(-135deg, #62632e, transparent, transparent);
      "
    >
      <div
        class="w-full overflow-auto rounded-3xl bg-black bg-contain bg-top max-md:rounded-b-none"
        style="background-image: linear-gradient(transparent, black), url(/images/dorito-bg.gif)"
      >
        <ChildReadyToJoin
          v-if="step === 'init'"
          :asset-selector-visible="isAssetSelectorVisible"
          :swap-status="swapStatus"
          @close="onClose"
          @hide-asset-selector="onHideAssetSelector"
          @on-buy="onBuyDorito"
          @show-asset-selector="onShowAssetSelector"
          @status-change="onStatusChange"
        />
        <ChildConfirm
          v-else-if="
            step === 'confirm' &&
            quoteRaw?.quote.route &&
            'tx' in quoteRaw.quote &&
            quoteRaw.quote.tx
          "
          :amount-in="amountIn"
          :is-expected-to-join-c-c="isExpectedToJoinCC"
          :open="true"
          :request-id="quoteRaw.quote.requestId"
          :route="quoteRaw.quote.route"
          :swap-status="swapStatus"
          :tx="quoteRaw.quote.tx"
          @close="step = 'init'"
          @signed="onSigned"
          @status-change="onStatusChange"
        />
      </div>
    </div>
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import BigNumber from 'bignumber.js'
import ChildReadyToJoin from './CompDoritoJoin/ChildReadyToJoin.vue'
import ChildConfirm from './CompDoritoJoin/ChildConfirm.vue'
import { useDoritoJoinModalStore } from '~/store/doritoJoinModalStore'
import type { QuoteWithAmountIn } from '~/types/dorito'
import { useSnackbarMessage } from '~/store/snackbar'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { type SwapStatus, isAboveCCThreshold } from '~/utils/swap'

const snackbar = useSnackbarMessage()
const swapkitWalletStore = useSwapkitWalletStore()
const { isCCMember, CCBalance } = storeToRefs(swapkitWalletStore)
const doritoJoinModalStore = useDoritoJoinModalStore()
const { hideDoritoJoinModal } = doritoJoinModalStore
const { isDoritoJoinModalVisible } = storeToRefs(doritoJoinModalStore)
const runtimeConfig = useRuntimeConfig()
const doritoAmount = runtimeConfig.public.CC_AMOUNT

const step = ref<'init' | 'confirm'>('init')
const swapStatus = ref<SwapStatus>('canSwap')
const quoteRaw = ref<IntegrationQuote<'rango'> | null>(null)
const amountIn = ref<string>('0')
const isAssetSelectorVisible = ref(false)

const isExpectedToJoinCC = computed(() => {
  const outputAmount = quoteRaw.value?.quote.route?.outputAmount
  return isAboveCCThreshold({
    CCBalance: CCBalance.value,
    expectedOutput: outputAmount ? BigNumber(outputAmount) : undefined,
    threshold: parseFloat(doritoAmount),
  })
})

const onBuyDorito = (quoteWithAmountIn: QuoteWithAmountIn) => {
  if (
    !quoteWithAmountIn.quoteRaw?.quote.route ||
    !('tx' in quoteWithAmountIn.quoteRaw.quote) ||
    !quoteWithAmountIn.quoteRaw.quote.tx
  ) {
    const title = 'Missing quote or transaction'
    window.newrelic?.noticeError(title, { data: { membership: true } })
    snackbar.addWarning({
      title,
    })
    return
  }
  quoteRaw.value = quoteWithAmountIn.quoteRaw
  amountIn.value = quoteWithAmountIn.amountIn
  step.value = 'confirm'
}
const onClose = () => {
  hideDoritoJoinModal()
  step.value = 'init'
}
const onHideAssetSelector = () => {
  isAssetSelectorVisible.value = false
}
const onShowAssetSelector = () => {
  isAssetSelectorVisible.value = true
}
const onStatusChange = (status: SwapStatus) => {
  swapStatus.value = status
}
const onSigned = () => {
  hideDoritoJoinModal()
  swapStatus.value = 'canSwap'
  step.value = 'init'
}
</script>
