<template>
  <div
    class="flex min-h-[582px] flex-col gap-5 overflow-auto bg-[#ECBA33]/10 p-6 !leading-[normal]"
    style="background-image: linear-gradient(#0003, #000)"
  >
    <div class="larken mt-auto flex w-full gap-4 text-xl max-sm:flex-col md:items-center">
      <div v-if="txStatus === 'confirming'" class="flex w-full items-center gap-2">
        <span>Transaction</span>
        <span class="text-[#33FF99]">Processing...</span>
        <button
          class="ml-auto rounded-lg bg-white/10 p-1.5 text-sm text-white/50 hover:bg-[#ECBA33]/10 hover:text-white"
        >
          <Icon icon="ph:arrows-in-simple" />
        </button>
      </div>
      <span v-else>Confirm Token Swap</span>
    </div>
    <template v-if="txStatus === 'confirming'">
      <ProgressBar class="mt-2.5" :estimated-time-in-seconds="route.estimatedTimeInSeconds" />
    </template>
    <TransactionHeader
      class="mt-2.5"
      :tx-status
      :amount-in="props.amountIn"
      :expected-amount-in="expectedAmountIn"
      :expected-output="route.outputAmount"
      :in-asset-chain-name="route.from.blockchain"
      :in-asset-name="route.from.symbol"
      :in-icon="route.from.image"
      :out-asset-chain-name="route.to.blockchain"
      :out-asset-name="route.to.symbol"
      :out-icon="route.to.image"
    />
    <CollapseBox v-if="txStatus === 'confirming'" class="mt-2.5" title="Transaction Details">
      <TransactionDetail
        :in-asset-name="route.from.symbol"
        :is-expected-to-join-c-c="props.isExpectedToJoinCC"
        :out-asset-name="route.to.symbol"
        :provider="route.swapper.title"
        :swapper-logo="route.swapper.logo"
        :fiat-price-ratio="fiatPriceRatio"
        :fees="fees"
        :estimated-time-in-seconds="route.estimatedTimeInSeconds"
        :amount-out-min="route.outputAmountMin"
        :price-impact-percentage="priceImpactPercentage"
        is-join-c-c-transaction
      />
    </CollapseBox>
    <TransactionDetail
      v-else
      :in-asset-name="route.from.symbol"
      :is-expected-to-join-c-c="props.isExpectedToJoinCC"
      :out-asset-name="route.to.symbol"
      :provider="route.swapper.title"
      :swapper-logo="route.swapper.logo"
      :fiat-price-ratio="fiatPriceRatio"
      :fees="fees"
      :estimated-time-in-seconds="route.estimatedTimeInSeconds"
      :amount-out-min="route.outputAmountMin"
      :price-impact-percentage="priceImpactPercentage"
      is-join-c-c-transaction
    />
    <div v-if="!txStatus" class="grid grid-cols-2 gap-5">
      <button
        class="border-full larken box-border cursor-pointer rounded-xl border border-solid border-white/5 px-5 py-3 text-center text-sm font-bold hover:bg-opacity-90"
        @click="onCloseDialog"
      >
        Cancel
      </button>
      <button
        :class="[
          'larken box-border w-full rounded-xl bg-[#C99D29] px-5 py-3 text-center text-sm font-bold text-[#1D2021] hover:bg-opacity-90',
          {
            'cursor-pointer': !isDisabled,
            'opacity-50': isDisabled,
          },
        ]"
        :disabled="isDisabled"
        @click="onConfirm"
      >
        {{ swapActionName }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import type { QuoteSimulationResult, SwapResponse } from 'rango-sdk-basic'
import TransactionDetail from '../CompConfirm/TransactionDetail.vue'
import TransactionHeader from '../CompConfirm/TransactionHeader.vue'
import CollapseBox from '../CompConfirm/CollapseBox.vue'
import ProgressBar from '../CompConfirm/ProgressBar.vue'
import { useSnackbarMessage } from '~/store/snackbar'
import { useRangoRouteStore } from '~/store/rangoRouteStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { getAssetId, hasMessage } from '~/utils/main'
import { getHumanError } from '~/utils/humanErrors'
import { intToBigNumber } from '~/utils/numbers'
import { useFiat } from '~/composables/fiat'
import { useRango } from '~/composables/rango'
import { useSwapkitRouteStore } from '~/store/swapkitRouteStore'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import {
  type SwapStatus,
  doesAmountExceedMax,
  getBalance,
  getNegativePriceImpactPercentage,
  isSwapDisabled,
  swapActionNames,
} from '~/utils/swap'
import { isGasAsset } from '~/utils/swapkit'
import { useAnalytics } from '~/composables/analytics'

const analytics = useAnalytics()
const snackbar = useSnackbarMessage()
const swapkitWalletStore = useSwapkitWalletStore()
const { wallets, isWalletConnected } = storeToRefs(swapkitWalletStore)
const swapkitRouteStore = useSwapkitRouteStore()
const rangoRouteStore = useRangoRouteStore()
const { submitSwap } = rangoRouteStore
const fiat = useFiat()
const rango = useRango()

type SubConfirmProps = {
  txStatus?: 'confirming' | 'success' | 'failed'
  amountIn: string
  isExpectedToJoinCC: boolean
  requestId: string
  route: QuoteSimulationResult
  swapStatus: SwapStatus
  tx: NonNullable<SwapResponse['tx']>
}
const props = defineProps<SubConfirmProps>()
const { requestId, route, tx } = toRefs(props)

const emits = defineEmits<{
  close: []
  confirm: []
  statusChange: [SwapStatus]
  signed: []
}>()

const fiatPriceStore = useFiatPriceStore()

const amountIn = computed(() => {
  // TODO check for NaN
  return parseFloat(props.amountIn)
})
const inAssetId = computed(() => {
  return getAssetId(
    route.value.from.blockchain,
    route.value.from.symbol,
    route.value.from.address ?? undefined,
  )
})
const isDisabled = computed(() => {
  return isSwapDisabled(props.swapStatus)
})
const fiatPriceRatio = computed(() => {
  if (!fiatPrices.value.in || !fiatPrices.value.out) {
    return 0
  }
  return fiatPrices.value.out / fiatPrices.value.in
})
const fiatPrices = computed(() => {
  if (props.route.from.usdPrice && props.route.to.usdPrice) {
    return { in: props.route.from.usdPrice, out: props.route.to.usdPrice }
  }
  const fiatPriceIn = fiatPriceStore.getFiatPriceAmount(inAssetId.value)
  const fiatPriceOut = fiatPriceStore.getFiatPriceAmount(
    getAssetId(
      route.value.from.blockchain,
      route.value.from.symbol,
      route.value.from.address ?? undefined,
    ),
  )
  return { in: fiatPriceIn, out: fiatPriceOut }
})
const expectedAmountIn = computed(() => {
  const balance = getBalance({
    assetId: inAssetId.value,
    chain: route.value.from.blockchain,
    wallets: wallets.value,
  })
  const maxAmountIn = balance - fees.value.networkUsd
  const amountInExceedsMax = doesAmountExceedMax({
    amount: amountIn.value,
    maxAmount: maxAmountIn,
    isGasAsset: isGasAsset({
      address: route.value.from.address,
      amount: amountIn.value,
      assetId: inAssetId.value,
    }),
    isWalletConnected: isWalletConnected.value,
  })
  return amountInExceedsMax ? maxAmountIn : null
})
const expectedOutputUsd = computed(() => {
  return fiat
    .getExpectedOutputUsd({
      address: route.value.to.address ?? undefined,
      chain: route.value.to.blockchain,
      expectedOutput: intToBigNumber(route.value.outputAmount, route.value.to.decimals),
      expectedOutputUsd: route.value.outputAmountUsd,
      ticker: route.value.to.symbol,
    })
    .toNumber()
})
const fees = computed(() => {
  const affiliateFeeUsd = amountIn.value * swapkitRouteStore.referrerFeeRatio
  return rango.swapFeesToFeesDisplay({
    affiliateFeeUsd,
    expectedOutputUsd: expectedOutputUsd.value,
    swapFees: route.value.fee,
    valueIn: amountIn.value,
  })
})
const confirmSwapActionNames = { ...swapActionNames, swapping: 'Confirming...', canSwap: 'Confirm' }
const priceImpactPercentage = computed(() => {
  return getNegativePriceImpactPercentage({
    outValueUsd: expectedOutputUsd.value,
    valueIn: amountIn.value * fiatPrices.value.in,
  }).toNumber()
})
const swapActionName = computed(() => {
  return confirmSwapActionNames[props.swapStatus]
})

const onCloseDialog = () => {
  emits('close')
}

const onConfirm = async () => {
  const swapData = {
    amount: amountIn.value,
    fromChain: props.route.from.blockchain,
    fromAsset: props.route.from.symbol,
    toChain: props.route.to.blockchain,
    toAsset: props.route.to.symbol,
    quoteAmount: props.route.outputAmount,
  }
  emits('statusChange', 'swapping')
  analytics.track('swap-signing', swapData)
  try {
    const walletAddress = wallets.value.SOLANA?.address
    if (!walletAddress) {
      throw new Error('No Solana wallet connected')
    }

    await submitSwap({
      amountIn: props.amountIn,
      expectedOutputUsd: expectedOutputUsd.value,
      fees: fees.value,
      fromAddress: walletAddress,
      quote: route.value,
      requestId: requestId.value,
      toAddress: walletAddress,
      tx: tx.value,
    })
    emits('signed')
    analytics.track('swap-signed', swapData)
    /**
     * Swap
     * @reference https://v2.growthchannel.io/activate/pixels
     */
    const swapPixel = document.createElement('script')
    swapPixel.async = true
    swapPixel.defer = true
    swapPixel.src = 'https://pxl.growth-channel.net/s/d6b6e5da-99b7-49d6-a2e6-8c6af450ae9f'
    document.body.appendChild(swapPixel)
  } catch (error) {
    const title = 'Failed to submit Rango swap'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    const e = title + (errorMessage && `: ${errorMessage}`)
    window.newrelic?.noticeError(e)
    console.error(e)
    snackbar.addError({
      title,
      text: getHumanError(errorMessage),
    })
  }
}
</script>
